import React from "react";
import "../../Style/main.css";
import VideoBox from "./VideoBox";

const VideoList = ({ data, next }) => {

    return (

        <div className="row">
            {data?.slice(0, next)?.map((item, index) => (
                <VideoBox item={item} />
            )
            )}
        </div>
    )
}
export default VideoList