import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { FaArrowRight } from 'react-icons/fa';
import post_img from "../../assets/img.jpg";

const VideoBox = ({ item }) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="col-lg-4 col-md-6">
      <div className="filter_data" onClick={() => setModalShow(true)}>
        <div className="data_img" style={{ background: `url(${item?.thumbnail_default ? item?.thumbnail_default : post_img})` }}></div>
        <div className="data_text">
          <h6>{item?.title ? item?.title : "No Title "}</h6>
          <p style={{ textOverflow: 'ellipsis', maxWidth: '170px', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.speakers}</p>
          <div className="arrow_icon"><FaArrowRight /></div>
        </div>
      </div>
      <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Body>
          <div className="hover_box">
            <a href={item?.url} target="_blank">
              <div className="data_img" style={{ background: `url(${item?.thumbnail_default ? item?.thumbnail_default : post_img})` }}></div>
            </a>
            <div className="data_text">
              <a href={item?.url} target="_blank">
                <h6>{item?.title ? item?.title : "No Title "}</h6>
              </a>
              <ul>
                <li><p><span>Speaker :</span>{item?.speakers}</p></li>
                <li><p><span>Video Type :</span>{item?.type}</p></li>
                <li><p><span>Video Length :</span>{item?.length}</p></li>
                {item?.event ?
                  <li><p><span>Event :</span>{item?.event}</p></li>
                  :
                  <></>}
              </ul>
              <a href={item?.url} target="_blank" className="arrow_icon"><FaArrowRight /></a>
            </div>
          </div>
          <p className="description">{item?.description ? item?.description : "No Description "}</p>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default VideoBox
