import React, { useEffect, useState } from "react";
import "../../Style/main.css";
import ReactPlayer from "react-player";
import Play_btn from "../../assets/Play-btn.png";
import axios from "axios";
import { connect } from "react-redux";

const Featured = (props) => {
    const [featuredData, setFeaturedData] = useState({});

    let styles = {
        colorwhite: "#FFF",
        colorblack: "#000",
    };
    useEffect(() => {
        axios
            .get("/api/featured.json")
            .then((response) => {
                setFeaturedData(response?.data);
            })
            .catch((err) => {
                console.log(err, "error");
            });
    }, []);

    return (
        <div className="video_sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="video_text">
                            <h6>This Week’s Featured Video</h6>

                            <h2
                                style={{
                                    color: props.mode ? "" : styles.colorwhite,
                                }}
                            >
                                {featuredData?.title}
                            </h2>
                            <p>{featuredData?.speakers}</p>


                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className={props.mode ? "video_div_light" : "video_div_dark"}>
                            <ReactPlayer
                                url={featuredData?.url}
                                playing={false}
                                volume={1}
                                width="100%"
                                height="388px"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        mode: state.mode.themeMode,
    };
};

export default connect(mapStateToProps, {})(Featured);
