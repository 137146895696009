import React, { forwardRef, useEffect, useRef } from 'react'

const Dropdown = forwardRef(({ s }) => {


    return (
        <>
            <option value="" selected disabled hidden>Select Here</option>
            <option
                value={s}
            >
                {s}
            </option>
        </>


    )
})

export default Dropdown
