import React from "react";
import Footer from "../footer/footer";
import Header from "../Header/Header";
import { connect } from "react-redux";

const Contact = (props) => {
  let styles = {
    colorwhite: "#FFF",
    colorblack: "#000",
  };
  return (
    <div className="home_body">
      <Header />
      <div className="inner_body">
        <h2
          className="text-center"
          style={{
            color: props.mode ? styles.colorblack : styles.colorwhite,
          }}
        >
          Contact Us Page
        </h2>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    mode: state.mode.themeMode,
  };
};

export default connect(mapStateToProps, {})(Contact);
