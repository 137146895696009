import React from "react";
import Featured from "../component/Featured/Featured";
import Search from "../component/Search/Search";
import Footer from "../footer/footer";
import Header from "../Header/Header";


const Home = (props) => {
  return (
    <div className="home_body">
      <Header />
      <div className="inner_body">
        <div className="filter">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Search />
              </div>
            </div>
          </div>
        </div>

        <Featured />
      </div>
      <Footer />
    </div>
  );
};
export default Home;
