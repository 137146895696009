import React, { useEffect, useState } from "react";
import "./header.css";
import Dark_Logo from "../assets/Dark_Logo.png";
import Light_Logo from "../assets/Light_Logo.png";

import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ThemeMode } from "../Redux/Actions";

const Header = (props) => {
  const [showMode, setShowMode] = useState(false);

  let styles = {
    colorwhite: "#FFF",
    colorblack: "#000",
  };

  useEffect(() => {
    if (props.mode) {
      document.body.classList.remove("black");
      document.body.classList.add("white");
      return;
    }

    document.body.classList.remove("white");
    document.body.classList.add("black");

    return () => { };
  }, [props.mode]);

  const HandlTheme = () => {
    props.ThemeMode(!props.mode);
    if (props.mode) {
      document.body.classList.remove("black");
      document.body.classList.add("white");
      return;
    }
    document.body.classList.remove("white");
    document.body.classList.add("black");
  };
  return (
    <>
      <header className="header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12">
              <div className="align-right">
                <div className="header_menu">
                  <Navbar expand="lg ">
                    <Navbar.Brand>
                      <Link to="/">
                        <img
                          src={props.mode ? Dark_Logo : Light_Logo}
                          alt="logo"
                        />
                      </Link>
                    </Navbar.Brand>
                    <div className="align-right d-flex justify-content-lg-end justify-content-start flex-lg-row flex-row-reverse">

                      <div className="icon_box">

                        <p
                          style={{
                            color: props.mode ? "" : styles.colorwhite,
                          }}
                        >
                          {props.mode ? "Dark" : "Light"}
                          <button
                            style={{
                              display: showMode === false ? "block" : "none",
                            }}
                            className={`toggle_button ${props.mode ? "toggle_button_light" : ""
                              }`}
                            onClick={() => {
                              HandlTheme();
                            }}
                          ></button>
                        </p>
                      </div>
                    </div>
                  </Navbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="inner_banner">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="header_text">
                <h1>Helping You Find Great QA Videos</h1>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    mode: state.mode.themeMode,
  };
};

export default connect(mapStateToProps, {
  ThemeMode,
})(Header);
