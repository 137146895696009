import React from "react";
import "./footer.css";
import Dark_Logo from "../assets/Dark_Logo.png";
import Light_Logo from "../assets/Light_Logo.png";
import folks from "../assets/folks.svg";
import { TiSocialTwitter } from "react-icons/ti";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Footer = (props) => {
  let styles = {
    colorwhite: "#FFF",
    colorblack: "#404040",
    bgdarktheme: "#011514",
    coloricon: "#225a57",
  };
  return (
    <footer
      style={{
        backgroundColor: props.mode ? "" : styles.bgdarktheme,
      }}
    >
      <div class="container">
        <div className="row">
          <div className="col-md-8">
            <div className="logo_number">
              <Link to="/">
                <img src={props.mode ? Dark_Logo : Light_Logo} alt="logo" />
              </Link>
              {/* <p>
                <span
                  style={{
                    color: props.mode ? "" : styles.colorwhite,
                  }}
                >
                  Call Us Now:
                </span>
                <a href="tel:+1 23 8376 6284">+1 23 8376 6284</a>
              </p> */}
            </div>
          </div>
          <div className="col-md-4">
            <ul className="social_icon">
              {/* <li>
                <Link to="/">
                  <FaFacebook color={props.mode ? "" : styles.coloricon} />
                </Link>
              </li> */}
              <li>
                <Link to="/">
                  <TiSocialTwitter color={props.mode ? "" : styles.coloricon} />
                </Link>
              </li>
              {/* <li>
                <Link to="/">
                  <FaLinkedinIn color={props.mode ? "" : styles.coloricon} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <FaInstagram color={props.mode ? "" : styles.coloricon} />
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
        <div className={`row ${props.mode ? "light_border" : "dark_border"}`}>
          <div className="col-md-6">
            {/* <ul className="footer_menu">
              <li>
                <Link
                  to="#"
                  style={{
                    color: props.mode ? "" : styles.colorwhite,
                  }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  style={{
                    color: props.mode ? "" : styles.colorwhite,
                  }}
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  style={{
                    color: props.mode ? "" : styles.colorwhite,
                  }}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  style={{
                    color: props.mode ? "" : styles.colorwhite,
                  }}
                >
                  Contact
                </Link>
              </li>
            </ul> */}
            <p
              style={{
                color: props.mode ? styles.colorblack : styles.colorwhite,
              }}
            >
              Our goal is to help you find the best QA related content on the
              web. If there’s videos you think we’re missing reach out on
              Twitter
            </p>
          </div>
          <div className="col-md-6">
            {/* <div className="othe_details">
              <a
                href="mailto:info@domain.com"
                style={{
                  color: props.mode ? "" : styles.colorwhite,
                }}
              >
                <FaEnvelope />
                info@domain.com
              </a>
              <a
                href="https://goo.gl/maps/yKfNjfrK9mR7qHuq7"
                target="_blank"
                style={{
                  color: props.mode ? "" : styles.colorwhite,
                }}
              >
                <FaMapMarkerAlt />
                121 King Street, New York, NY 3000, USA.
              </a>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="Copyright">
              <p
                style={{
                  color: props.mode ? "" : styles.colorwhite,
                }}
              >
                © Copyright 2022 Curated QA. All Rights Reserved.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            {/* <ul className="footer_menu f_m_two">
              <li className="footer_li_light">
                <Link
                  to="/"
                  style={{
                    color: props.mode ? "" : styles.colorwhite,
                  }}
                >
                  Disclaimer
                </Link>
              </li>
              <li className={props.mode ? "footer_li_light" : "footer_li_dark"}>
                <Link
                  to="/"
                  style={{
                    color: props.mode ? "" : styles.colorwhite,
                  }}
                >
                  Site Map
                </Link>
              </li>
              <li className={props.mode ? "footer_li_light" : "footer_li_dark"}>
                <Link
                  to="/"
                  style={{
                    color: props.mode ? "" : styles.colorwhite,
                  }}
                >
                  Privacy Policy
                </Link>
              </li>
            </ul> */}
            <p className="footer_link_text_p">
              <a
                href="https://wonderproxy.com"
                target="_blank"
                className="footer_link_text"
                style={{
                  color: props.mode ? styles.colorblack : styles.colorwhite,
                }}
              >
               <img src={folks} />From the friendly folks at WonderProxy
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => {
  console.log(state, "state!!!!!");
  return {
    mode: state.mode.themeMode,
  };
};

export default connect(mapStateToProps, {})(Footer);
